@font-face {
  font-family: 'PlusJakartaSans-Light';
  src: local('PlusJakartaSans-Light'), url(./Assets/PlusJakartaSans-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'PlusJakartaSans-Regular';
  src: local('PlusJakartaSans-Regular'), url(./Assets/PlusJakartaSans-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'PlusJakartaSans-Bold';
  src: local('PlusJakartaSans-Bold'), url(./Assets/PlusJakartaSans-Bold.woff2) format('woff2');
}

body {
  margin: 0;
  font-family: PlusJakartaSans-Regular !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  display: block;
  text-decoration: initial;
}

canvas {
  width: 100% !important;
  height: auto !important;
}

h1 {
  margin: 20px 0px 20px 0px;
}

.text-inherit {
  color: inherit !important;
}
.text-salmon {
	color: salmon !important;
}
.text-navy {
  color: rgba(22, 40, 92) !important;
}
.text-gray {
  color: rgba(84, 84, 84) !important;  
}
.text-lite-gray {
  color: rgba(163,163,177,1) !important;
}
.text-black {
  color: rgba(8, 8, 8) !important;  
}
.text-white {
  color: white !important;  
}
.text-center {
  text-align: center !important;
}
.text-underline {
  text-decoration: underline !important;
}

.background-initial {
  background-color: initial !important;
}
.background-white {
  background-color: white !important;
}
.background-salmon {
  background-color: salmon !important;
}
.background-light-salmon {
  background-color: rgba(255, 245, 244) !important;
}
.background-navy {
  background-color: rgba(22, 40, 92) !important;
}

.main-content-container {
  min-width: 570px !important;
}
.sideNavLink {
  color: rgba(163,163,177,1);
}

.border-light {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 1%), -1px 1px 1px 0px rgb(0 0 0 / 1%), 0px 1px 3px 0px rgb(0 0 0 / 1%) !important;
}
.box-shadow-0 {
  box-shadow: initial !important;
}

.padding-0: {
  padding: 0px !important;
}
.margin-0: {
  margin: 0px !important;
}

.width-50 {
  width: 50%;
}
.width-100 {
  width: 100%;
}
.main-content {
  padding: 20px 30px;
}

.display-block {
  display: block !important;
}

.salmon-button {
  background-color: salmon !important;
  color: white !important;
  padding: 5px 20px 5px 20px !important;
  border: 1px solid white !important;
}
.salmon-button:hover {
  background-color: white !important;
  color: salmon !important;
  border: 1px solid salmon !important;
}
.salmon-outline-button {
  background-color: white !important;
  border: 1px solid salmon !important;
  border-radius: 15px;
  color: salmon !important;
  margin: 0px 10px 0px 10px !important;
  padding: 5px 20px 5px 20px !important;
}

/* Material UI Overrides */
.MuiList-padding {
  padding: 0px !important;
}
.MuiDivider-root {
  background-color: rgba(0, 0, 0, 0.075) !important;
}
.MuiButton-root, .MuiTab-root {
  font-family: 'PlusJakartaSans-Regular' !important;
  text-transform: none !important;
}
.MuiPaper-elevation1 {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 1%), -1px 1px 1px 0px rgb(0 0 0 / 1%), 0px 1px 3px 0px rgb(0 0 0 / 1%) !important;    
}
.MuiDrawer-paperAnchorDockedLeft {
  border-right: 1px solid rgba(0, 0, 0, 3%) !important;
}
.MuiTabs-indicator {
  background-color: salmon !important;
}
.MuiTab-textColorInherit.Mui-selected {
  color: salmon !important;
}
.MuiTab-wrapper {
  font-size: 1rem;
}
.MuiInput-underline:after {
  border: initial !important;
}
.MuiSelect-select.MuiSelect-select {
  padding-left: 12px;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.MuiSwitch-colorSecondary.Mui-checked {
  color: salmon !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: salmon !important;
}
.MuiTableCell-root {
  border-bottom: initial !important;
  font-family: PlusJakartaSans-Regular !important;
}
.MuiCircularProgress-root {
  width: 40px !important;
  height: 40px !important;
  margin: 0 auto !important;
  display: block !important;
  color: salmon !important;
}
.Mui-checked {
  color: salmon !important;
}
.MuiSnackbarContent-root {
  background-color: rgba(22, 40, 92) !important;
}
.MuiSnackbarContent-message {
  padding: 8px 0;
  width: 100%;
  text-align: center;
}